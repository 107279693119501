.hamburger-icon {
    font-size: 30px;
    cursor: pointer;
    padding: 10px;
    background-color: #ff007f;
    color: white;
    text-align: center;
    width: 100%; /* Largura total do ícone */
  }
  
  .hamburger-menu {
    overflow: hidden;
    max-height: 0;
    transition: 1s; /* Transição suave */
    background-color: #8b0047;
    width: 100%;
    border-radius: 0px 0px 10px 10px;
    padding: 0; /* Remover padding quando fechado */
  }
  
  .hamburger-menu.open {
    max-height: 300px; /* Altura máxima quando aberto */
  }
  
  .hamburger-menu ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .hamburger-menu li {
    text-align: center;
    padding: 10px;
    
  }
  
  .hamburger-menu li a {
    text-decoration: none;
    transition: 0.2s;
    color: white;
    display: block;
  }
  
  .hamburger-menu li a:hover {
    background-color: #ff77bb;
  }
  