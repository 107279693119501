body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow-x: hidden;
}



li{
    padding: 10px;
    list-style: none;
    
}

li > a{
    text-decoration: none;
    color: white;
    padding: 10px;
    background-color: #FF0072;
}

ul{
    padding: 0;
}

nav{
    background-color: #64022e;
    padding: 20px;
    width: 100vw;
    
}

.button-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centraliza os botões horizontalmente */
    gap: 10px; /* Espaçamento entre os botões */
    margin-top: 20px; /* Espaçamento superior */
    transition: 0.2s;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.418);
  }
  
  .button-container a {
    background-color: #8B0000; /* Cor de fundo do botão */
    color: white; /* Cor do texto */
    padding: 10px 20px; /* Espaçamento interno do botão */
    text-align: center;
    text-decoration: none; /* Remove o sublinhado dos links */
    border-radius: 5px; /* Bordas arredondadas */
    font-size: 16px; /* Tamanho da fonte */
    width: 200px; /* Largura fixa para os botões */
    transition: background-color 0.3s ease; /* Transição suave para o hover */
  }

  .button-container:hover{
    background-color: #5F0000;
  }
  
  .button-container a:hover {
    background-color: #5F0000; /* Cor de fundo ao passar o mouse */
  }
  
  .button-container a:focus {
    outline: none; /* Remove o contorno padrão ao focar no link */
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.3); /* Adiciona uma sombra ao focar */
  }
  